// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-subject-js": () => import("/opt/build/repo/src/templates/subject.js" /* webpackChunkName: "component---src-templates-subject-js" */),
  "component---src-templates-subject-ru-js": () => import("/opt/build/repo/src/templates/subject-ru.js" /* webpackChunkName: "component---src-templates-subject-ru-js" */),
  "component---src-templates-article-js": () => import("/opt/build/repo/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prices-js": () => import("/opt/build/repo/src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-ru-contact-js": () => import("/opt/build/repo/src/pages/ru/contact.js" /* webpackChunkName: "component---src-pages-ru-contact-js" */),
  "component---src-pages-ru-index-js": () => import("/opt/build/repo/src/pages/ru/index.js" /* webpackChunkName: "component---src-pages-ru-index-js" */),
  "component---src-pages-ru-prices-js": () => import("/opt/build/repo/src/pages/ru/prices.js" /* webpackChunkName: "component---src-pages-ru-prices-js" */),
  "component---src-pages-s-js": () => import("/opt/build/repo/src/pages/s.js" /* webpackChunkName: "component---src-pages-s-js" */)
}

